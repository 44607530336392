import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import { SmartLink } from '../SmartLink';

import style from './CharityTeaser.module.scss';
import { removeQuery } from '../../utils/queryString';

const CharityTeaser = props => {
    const { title, description, cta, popup } = props;
    const router = useRouter();
    const pagePath = cta.link.url.replace(/\/$/, '');
    const link = `${removeQuery(router.asPath)}?pageId=${pagePath}`;

    return (
        <div className={style.el}>
            <div>
                <div className={[style.container, 'container'].join(' ')}>
                    <div className={style.content}>
                        {title !== '' ? (
                            <>
                                <div className={style.leftSide}>
                                    <h2>{title}</h2>
                                </div>
                                <div className={style.rightSide}>
                                    <div
                                        className={style.statsDesc}
                                        dangerouslySetInnerHTML={{
                                            __html: description,
                                        }}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div
                                    className={style.statsDesc}
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
            {cta.link.url !== '' ? (
                <div className={style.cta}>
                    <div className={[style.container, 'container'].join(' ')}>
                        <div className={style.ctaLink}>
                            {popup ? (
                                <SmartLink href={link} as={cta.link.url} hasArrow className='arrow-link'>
                                    {cta.text}
                                </SmartLink>
                            ) : (
                                <SmartLink href={cta.link.url} hasArrow className='arrow-link'>
                                    {cta.text}
                                </SmartLink>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className={style.cta}>
                        <div className={[style.container, 'container'].join(' ')}>
                            <div className={style.noCtaLink} />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

CharityTeaser.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    cta: PropTypes.object,
    popup: PropTypes.bool,
};

export default CharityTeaser;
