import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import style from './CountingNumbers.module.scss';

const CountingNumbers = props => {
    const { value } = props;
    const [isVisible, setIsVisible] = useState(false);
    const elRef = useRef(null);
    const splitValue = splitNumbersAndSymbols(value);
    const jsx = [];

    splitValue.forEach((item, index) => {
        if (item.type === 'number') {
            jsx.push(<CountingNumber key={index} selector={style.el} value={item.string} />)
        } else {
            jsx.push(<div key={index}>{item.string}</div>)
        }
    });

    useEffect(() => {
        const animate = (entries) => {
            if (entries[0].isIntersecting) {
                setIsVisible(true);
                observer.disconnect();
            }
        }

        const options = {
            rootMargin: '0px',
            threshold: 1.0,
        };
        const observer = new IntersectionObserver(animate, options);
        observer.observe(elRef.current);
    }, []);

    return (
        <div className={style.el + ' ' + (isVisible ? style.visible : '')} ref={elRef}>
            {jsx}
        </div>
    );
};

CountingNumbers.propTypes = {
    value: PropTypes.string,
};
export default CountingNumbers;

CountingNumbers.defaultProps = {};

function splitNumbersAndSymbols (inputString) {
    const result = [];
    let lastType;
    let currentBlock = '';

    for (const char of inputString) {
        switch (true) {
            // Number
            case /[0-9]/.test(char):
                if (lastType === 'number' || lastType === undefined) currentBlock += char;
                else {
                    result.push({ type: lastType, string: currentBlock });
                    currentBlock = char;
                }
                lastType = 'number';
            break;

            default:
                if (lastType === 'symbol' || lastType === undefined) currentBlock += char;
                else {
                    result.push({ type: lastType, string: currentBlock });
                    currentBlock = char;
                }
                lastType = 'symbol';
            break;
        }
    }

    result.push({ type: lastType, string: currentBlock });

    return result;
}

const CountingNumber = props => {
    const baseAnimTimeMS = 2500;
    const variableAnimTimeMS = 1500;
    const { value } = props;
    const numberRef = useRef(null);

    function easeOutCubic (t) {
        const t1 = t - 1;
        return t1 * t1 * t1 + 1;
    }

    useEffect(() => {
        const animate = (entries) => {
            if (entries[0].isIntersecting) {
                // Initial values
                const finalValue = parseInt(value);
                if (finalValue === 0) return;
                const animTime = baseAnimTimeMS + Math.random() * variableAnimTimeMS;
                const initTime = performance.now();

                // Interval
                const interval = setInterval(function () {
                    const t = (performance.now() - initTime) / animTime;

                    const currentValue = Math.floor(easeOutCubic(t) * finalValue);
                    if (numberRef && numberRef.current) numberRef.current.innerText = currentValue;

                    if (t >= 1) {
                        clearInterval(interval);
                    }
                }, 15);

                observer.disconnect();
            }
        }

        const options = {
            rootMargin: '0px',
            threshold: 1.0,
        };
        const observer = new IntersectionObserver(animate, options);
        observer.observe(numberRef.current);
    }, []);

    return (
        <div className={style.countingNumber} ref={numberRef}>
            {value}
        </div>
    )
}

CountingNumber.propTypes = {
    value: PropTypes.string,
    selector: PropTypes.string,
};

CountingNumber.defaultProps = {};
